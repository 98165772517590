import { Fragment } from "react"
import styled from "styled-components"

import { usePostOpenPresetClicked } from "src/data/analytics/queries/homeSettingsAnalyticsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePatchHome } from "src/data/organizations/queries/homeQueries"
import {
  useFetchIndoorClimateMonitoringPresets,
  useFetchNoiseMonitoringPreset,
  useFetchNoiseMonitoringPresets,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { DropdownListButton } from "src/ui/Button/DropdownListButton"
import { GridTable } from "src/ui/GridTable/GridTable"
import IndoorClimateIcon from "src/ui/icons/indoor-climate.svg"
import NoiseMonitoringIcon from "src/ui/icons/noise-monitoring.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export type TMonitoringPresetType = "noise" | "icm"
type TPresetDropdownOptions = { id: string; name: string }

type TMonitoringPreset = {
  type: TMonitoringPresetType
  name: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  availablePresets: TPresetDropdownOptions[]
  link: string
  selectedPreset: TPresetDropdownOptions
}

export function HomeMonitoringPresets({
  homeId,
  activeNoisePresetId,
  activeICMPresetId,
}: {
  homeId: string
  activeNoisePresetId?: string
  activeICMPresetId?: string
}) {
  const { t, langKeys } = useTranslate()

  const { orgId } = useOrganization()

  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets({ orgId })
  const fetchNoisePreset = useFetchNoiseMonitoringPreset({
    orgId,
    noisePresetId: activeNoisePresetId,
  })

  const noisePresets = fetchNoiseMonitoringPresets.data?.profiles || []

  const fetchICMPresets = useFetchIndoorClimateMonitoringPresets()
  const icmPresets = fetchICMPresets.data?.profiles || []

  const patchHome = usePatchHome()

  const homeNoisePreset = fetchNoisePreset.data ?? noisePresets[0]
  // Using mock data for ICM. The API for ICM is not implemented yet.
  const homeICMPreset =
    icmPresets.find((p) => p.id === activeICMPresetId) ?? icmPresets[0]

  const monitoringPresets: TMonitoringPreset[] = [
    {
      type: "noise",
      name: t(langKeys.sound_noise_monitoring),
      icon: NoiseMonitoringIcon,
      availablePresets: noisePresets,
      link: Routes.SettingsPresetsNoise.location().pathname,
      selectedPreset: homeNoisePreset,
    },
    {
      type: "icm",
      name: t(langKeys.indoor_climate),
      icon: IndoorClimateIcon,
      availablePresets: icmPresets,
      link: Routes.SettingsPresetsIndoorClimate.location().pathname,
      selectedPreset: homeICMPreset,
    },
  ]

  const header = [
    <div key="setting"> {t(langKeys.preset_settings_for)}</div>,
    <div key="preset"> {t(langKeys.preset_applied_preset)}</div>,
    <div key="open-preset-link"></div>,
  ]

  if (
    fetchNoiseMonitoringPresets.isLoading ||
    fetchICMPresets.isLoading ||
    fetchNoisePreset.isInitialLoading
  ) {
    return <Loading />
  }

  function handlePresetChange({
    presetType,
    preset,
  }: {
    presetType: TMonitoringPresetType
    preset: TPresetDropdownOptions
  }) {
    if (presetType === "noise") {
      patchHome.mutate({ orgId, homeId, data: { noise_profile_id: preset.id } })
    } else if (presetType === "icm") {
      patchHome.mutate({ orgId, homeId, data: { icm_profile_id: preset.id } })
    }
  }

  const rows = monitoringPresets.map((monitoringPreset) => {
    const Icon = monitoringPreset.icon

    return (
      <Fragment key={monitoringPreset.type}>
        <SettingsPresetBox>
          <Icon width={24} height={24} />

          <InternalLink to={monitoringPreset.link} color="unset">
            {monitoringPreset.name}
          </InternalLink>
        </SettingsPresetBox>

        <div>
          <PresetDropdownList
            availablePresets={monitoringPreset.availablePresets}
            selectedPreset={monitoringPreset.selectedPreset}
            onChange={(newPreset) =>
              handlePresetChange({
                preset: newPreset,
                presetType: monitoringPreset.type,
              })
            }
          />
        </div>

        <PresetBox>
          <OpenPresetLink
            presetType={monitoringPreset.type}
            selectedPreset={monitoringPreset.selectedPreset}
            homeId={homeId}
          />
        </PresetBox>
      </Fragment>
    )
  })

  const mobileRows = monitoringPresets.map((monitoringPreset) => {
    const Icon = monitoringPreset.icon

    return (
      <MobileBox key={monitoringPreset.type}>
        <MobilePresetBox>
          <div>
            <Icon width={24} height={24} />
          </div>
          <div>
            <InternalLink
              to={Routes.Settings.location().pathname}
              color="unset"
            >
              {monitoringPreset.name}
            </InternalLink>

            <PresetDropdownList
              availablePresets={monitoringPreset.availablePresets}
              selectedPreset={monitoringPreset.selectedPreset}
              onChange={(newPreset) =>
                handlePresetChange({
                  preset: newPreset,
                  presetType: monitoringPreset.type,
                })
              }
            />
          </div>
        </MobilePresetBox>

        <OpenPresetLink
          presetType={monitoringPreset.type}
          selectedPreset={monitoringPreset.selectedPreset}
          homeId={homeId}
        />
      </MobileBox>
    )
  })

  return (
    <div>
      <MText variant="heading3" marginBottom={spacing.XS}>
        {t(langKeys.preset_monitoring_presets)}
      </MText>
      <MText color="secondary">
        {t(langKeys.preset_monitoring_presets_description_rental_unit)}{" "}
        <ExternalLink color="secondary" href={"#"}>
          {t(langKeys.learn_more)}
        </ExternalLink>
      </MText>

      <TableWrapper>
        <GridTable
          header={header}
          rows={rows}
          mobileRows={mobileRows}
          templateColumns={`repeat(${header.length}, auto)`}
          horizontalScroll
        />
      </TableWrapper>
    </div>
  )
}

function OpenPresetLink({
  selectedPreset,
  presetType,
  homeId,
}: {
  selectedPreset: TPresetDropdownOptions
  presetType: TMonitoringPresetType
  homeId: string
}) {
  const { t, langKeys } = useTranslate()
  const { user_id: userId } = useGetUser()
  const { orgId } = useOrganization()

  const postOpenPresetClicked = usePostOpenPresetClicked()

  const org = useOrganization()
  const hasAdminAccess = org.orgAccessLogic.hasAdminAccess

  if (!hasAdminAccess) {
    return null
  }

  const presetLink =
    presetType === "noise"
      ? Routes.NoisePreset.location(selectedPreset?.id ?? "")
      : Routes.IndoorClimatePreset.location(selectedPreset?.id ?? "")

  return (
    <InternalLink
      to={presetLink}
      color="unset"
      beforeNav={() =>
        postOpenPresetClicked.mutate({
          userId,
          orgId,
          homeId,
          type: presetType,
        })
      }
    >
      {t(langKeys.preset_open_preset)}
    </InternalLink>
  )
}

function PresetDropdownList({
  availablePresets,
  selectedPreset,
  onChange,
}: {
  availablePresets: TMonitoringPreset["availablePresets"]
  selectedPreset: TPresetDropdownOptions
  onChange: (preset: TPresetDropdownOptions) => void
}) {
  return (
    <DropdownListButton
      displayValue={selectedPreset?.name ?? availablePresets[0]?.name}
      variant="minimal"
      size="medium"
      options={availablePresets.map((preset) => ({
        name: preset.name,
        value: preset.id,
        onClick: () => {
          onChange(preset)
        },
      }))}
    />
  )
}

function Loading() {
  return (
    <div>
      <MSkeleton width="100%" />
      <MSkeleton width="100%" />
      <MSkeleton width="100%" />
    </div>
  )
}

const TableWrapper = styled.div`
  margin-block: ${spacing.L};
`

const PresetBox = styled.div`
  text-align: right;
`

const SettingsPresetBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.XS};
`

const MobileBox = styled.div`
  display: flex;
  gap: ${spacing.L};
  align-items: center;
  justify-content: space-between;
`

const MobilePresetBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: ${spacing.XS};
  align-items: center;
`
